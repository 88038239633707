import setupTouch from 'alloyfinger'

const MAX = 9     // zero based number of screens

const screens = () => {
  let previous
  let current = 0

  let modalOpen = false

  const modal = {
    root: document.querySelector('[data-modal]'),
    open: document.querySelector('[data-modal-open]'),
    close: document.querySelector('[data-modal-close]'),
    download: document.querySelector('[data-modal-download]')
  }

  const viewers = Array.from(document.querySelectorAll('[data-viewer]'))

  const next = Array.from(document.querySelectorAll('[data-next]'))
  const prev = Array.from(document.querySelectorAll('[data-previous]'))

  const images = [
    Array.from(document.querySelectorAll('[data-screen]')),
    Array.from(document.querySelectorAll('[data-modal-screen]')),
  ]

  const captions = Array.from(document.querySelectorAll('[data-caption]'))

  const handleModalOpen = event => {
    event.preventDefault()

    modalOpen = true
    window.requestAnimationFrame(() => modal.root.classList.add('is-active'))
  }

  const handleModalClose = event => {
    event.preventDefault()

    modalOpen = false
    window.requestAnimationFrame(() => modal.root.classList.remove('is-active'))
  }

  const handleKeyDown = event => {
    if (!modalOpen) return

    if (event.key === 'ArrowLeft') handlePrevious()
    if (event.key === 'ArrowRight') handleNext()
  }

  const handleSwipe = event => {
    const direction = event.direction.toLowerCase()

    if (direction === 'right') handleNext()
    if (direction === 'left') handlePrevious()
  }

  const handleNext = event => {
    event && event.preventDefault()

    previous = current

    current = current + 1 > MAX
      ? 0
      : current + 1

    update()
  }

  const handlePrevious = event => {
    event && event.preventDefault()

    previous = current

    current = current - 1 < 0
      ? MAX
      : current - 1

    update()
  }

  const setupDownload = () => {
    const href = `https://www.morkredd.com/images/screens/${ current + 1 }-2x.jpg`
    modal.download.setAttribute('href', href)
  }

  const update = () => {
    // set download link
    setupDownload()

    // update UI
    window.requestAnimationFrame(() => {
      images.forEach(array => {
        array[previous].classList.remove('is-active')
        array[current].classList.add('is-active')
      })

      captions[previous].classList.remove('is-active')
      captions[current].classList.add('is-active')
    })
  }

  modal.open.addEventListener('click', handleModalOpen)
  modal.close.addEventListener('click', handleModalClose)

  next.forEach(node => node.addEventListener('click', handleNext))
  prev.forEach(node => node.addEventListener('click', handlePrevious))
  viewers.forEach(node => new setupTouch(node, { swipe: handleSwipe }))
  window.addEventListener('keydown', handleKeyDown)
}

export default screens
