const OFFSET = 25

const enter = viewport => {
  const nodes = [...document.querySelectorAll('[data-inview]')]

  return () => nodes.forEach((node, index) => {
    const bounds = node.getBoundingClientRect()

    const atBottom = viewport.height + window.scrollY >= document.body.offsetHeight
    const shouldEnter = bounds.top < viewport.height * (1 - (OFFSET / 100))

    if (!atBottom && !shouldEnter) return

    node.classList.add('is-inview')

    nodes.splice(index, 1)
  })
}

export default enter
