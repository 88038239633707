const ORB_MAX = 15
const DUO_MAX = 25
const TENTACLE_MAX = 20

const {
  origin,
  pathname
} = window.location

const path = origin === 'file://'
  ? pathname.replace('index.html', 'images/header/bg-orb.png')
  : origin + '/images/header/bg-orb.png'

const header = viewport => {
  const root = document.querySelector('[data-header]')

  const nodes = [
    'arrow',
    'buttons',
    'duo',
    'logo',
    'orb',
    'platforms',
    'pulse',
    'punchline',
    'social',
    'tentacle'
  ].reduce((accum, name) => {
    accum[name] = root.querySelector(`[data-header-${ name }]`)
    return accum
  }, {})

  const preparePunchline = () => new Promise(resolve => {
    const fragment = document.createDocumentFragment()

    let delay = 0

    nodes
      .punchline
      .textContent
      .split('')
      .map(letter => {
        const node = document.createElement('span')

        if (letter !== ' ') {
          node.classList.add('header-punchline-letter')
          node.style.transitionDelay = `${ .1 * delay }s`
          delay += 1
        } else {
          node.classList.add('header-punchline-space')
        }

        node.textContent = letter
        fragment.appendChild(node)
      })

    nodes.punchline.innerHTML = ''
    nodes.punchline.appendChild(fragment)
    resolve()
  })

  const prepareOrb = () => new Promise(resolve => {
    const image = document.createElement('img')

    const handleLoad = event => {
      nodes.orb.style.backgroundImage = `url("${ path }")`
      resolve()
    }

    image.addEventListener('load', handleLoad)
    image.setAttribute('src', path)
  })

  const animateOrb = () => new Promise(resolve => {
    const handleEnd = event => {
      if (event.propertyName === 'transform') {
        nodes.orb.classList.remove('has-transition')
        nodes.pulse.classList.add('is-pulsing')
        resolve()
      }
    }

    nodes.orb.addEventListener('transitionend', handleEnd)

    window.requestAnimationFrame(() => nodes.orb.classList.remove('is-scaled'))
  })

  const animatePunchline = () => new Promise(resolve => {
    nodes
      .punchline
      .lastChild
      .addEventListener('transitionend', resolve)

    window.requestAnimationFrame(() => nodes.punchline.classList.add('is-ready'))
  })

  const animateLogoAndDuo = () => new Promise(resolve => {
    const animation = [nodes.duo, nodes.logo].map(node => {
      return new Promise(resolve => node.addEventListener('transitionend', resolve))
    })

    Promise
      .all(animation)
      .then(() => {
        nodes.duo.classList.remove('has-transition')
        resolve()
      })

    window.requestAnimationFrame(() => {
      nodes.duo.classList.remove('is-scaled')
      nodes.logo.classList.add('is-ready')
    })
  })

  const animateRest = () => new Promise(resolve => {
    const animations = [nodes.arrow, nodes.buttons, nodes.platforms, nodes.social].map(node => {
      return new Promise(resolve => node.addEventListener('transitionend', resolve))
    })

    Promise.all(animations).then(resolve)

    window.requestAnimationFrame(() => {
      [nodes.arrow, nodes.buttons, nodes.platforms, nodes.social].forEach(node => node.classList.add('is-ready'))
    })
  })

  const prepareParallax = () => {
    let ticking

    const handleEvent = event => {
      if (ticking) return

      window.requestAnimationFrame(() => handleMove(event))
      ticking = true
    }

    const handleMove = event => {
      if (window.scrollY >= viewport.height) {
        ticking = false
        return
      }

      const halfWidth = viewport.width / 2
      const halfHeight = viewport.height / 2

      const x = event.clientX - halfWidth
      const y = event.clientY - halfHeight

      const relativeX = x / halfWidth
      const relativeY = y / halfHeight

      nodes.duo.style.transform = `translate3d(${ relativeX * DUO_MAX }px, ${ relativeY * DUO_MAX }px, 0)`
      nodes.orb.style.transform = `translate3d(${ relativeX * ORB_MAX }px, ${ relativeY * ORB_MAX }px, 0)`
      nodes.pulse.style.transform = `translate3d(${ relativeX * ORB_MAX }px, ${ relativeY * ORB_MAX }px, 0)`
      nodes.tentacle.style.transform = `translate3d(${ relativeX * TENTACLE_MAX }px, ${ relativeY * TENTACLE_MAX }px, 0)`

      ticking = false
    }

    window.addEventListener('mousemove', handleEvent)
  }

  const sequence = [
    preparePunchline,
    prepareOrb,
    animateOrb,
    animatePunchline,
    animateLogoAndDuo,
    animateRest,
    prepareParallax
  ]

  sequence.reduce(
    (accum, current) => accum.then(current),
    Promise.resolve()
  )
}

export default header
