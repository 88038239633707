const inview = checkFuncs => {
  let ticking

  const handleEvent = () => {
    if (ticking) return

    window.requestAnimationFrame(handleFrame)
    ticking = true
  }

  const handleFrame = () => {
    checkFuncs.forEach(fx => fx())
    ticking = false
  }

  handleEvent()

  window.addEventListener('scroll', handleEvent, { passive: true })
}

export default inview
