import createInview from './providers/inview.js'
import createLight from './providers/light.js'
import createViewport from './providers/viewport.js'

import handleEnter from './handlers/enter.js'
import handleSticky from './handlers/sticky.js'

import setupBackground from './sections/background.js'
import setupHeader from './sections/header.js'
import setupScreens from './sections/screens.js'
import setupTrailer from './components/trailer.js'

const main = () => {
  createLight()

  const viewport = createViewport()

  setupBackground()
  setupHeader(viewport)
  setupScreens()
  setupTrailer()

  const checkFuncs = [
    handleEnter,
    handleSticky
  ].map(fx => fx(viewport))

  const inview = createInview(checkFuncs)
}

document.addEventListener('DOMContentLoaded', main)
