import emitter from '../utils/emitter.js'

const background = () => {
  const root = document.querySelector('[data-background]')
  const target = document.querySelector('[data-background-target]')

  const update = () => {
    const { top } = target.getBoundingClientRect()
    const scroll = window.scrollY

    root.style.top = `${ top + scroll }px`
  }

  update()

  emitter.on('resize', update)
}

export default background
