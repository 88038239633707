import emitter from '../utils/emitter.js'

const viewport = () => {
  let timer

  const cache = {}

  const capture = () => {
    cache.width = window.innerWidth
    cache.height = window.innerHeight

    emitter.emit('resize')
  }

  const handleResize = event => {
    clearTimeout(timer)
    timer = setTimeout(capture, 250)
  }

  capture()

  window.addEventListener('resize', handleResize)

  return cache
}

export default viewport
