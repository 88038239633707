import { tsParticles } from 'tsparticles'

const {
  origin,
  pathname
} = window.location

let path

if (origin === 'file://') {
  path = pathname.split('/')
  path.pop()
  path = path.concat(['json/particles.json'])
  path = path.join('/')
} else {
  path = origin + '/json/particles.json'
}

const light = () => {
  tsParticles
    .loadJSON('light', path)
    .then(container => {
      console.log('Light configuration loaded successfully.')
    })
    .catch(error => {
      console.error(error)
    })
}

export default light
