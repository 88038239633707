const videos = [
  'KUr8s5LCeyA',
  '7Rf_t-RLXPw'
]

const setup = (root, index) => {
  const nodes = [
    'button',
    'thumbnail'
  ].reduce((accum, name) => {
    accum[name] = root.querySelector(`[data-trailer-${ name }]`)
    return accum
  }, {})

  const player = new window.YT.Player(`embed-${ index }`, {
    playerVars: {
      controls: 0,            // hide controls
      enablejsapi: 1,         // enable API
      iv_load_policy: 3,      // hide video annotations
      modestbranding: 1       // use minimal branding
    }
  })

  const handleClick = event => {
    event.preventDefault()
    window.requestAnimationFrame(() => root.classList.add('is-playing'))
  }

  const handleEnd = event => {
    player.loadVideoById(videos[index])
    player.getIframe().requestFullscreen()
  }

  nodes.button.addEventListener('click', handleClick)
  nodes.thumbnail.addEventListener('transitionend', handleEnd)
}

const trailer = () => {
  const inject = () => {
    const tag = document.createElement('script')
    tag.setAttribute('async', true)
    tag.setAttribute('src', 'https://www.youtube.com/iframe_api')
    document.body.appendChild(tag)
  }

  const handleReady = () => {
    const roots = Array.from(document.querySelectorAll('[data-trailer]'))
    roots.forEach((node, index) => setup(node, index))
  }

  window.onYouTubeIframeAPIReady = handleReady

  inject()
}

export default trailer
